import React from "react";
import ReactApexChart from "react-apexcharts";

export default function BarChart({ dates, data }) {
  const options = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: dates,
      // categories: [
      //   "05/24",
      //   "05/25",
      //   "05/26",
      //   "05/27",
      //   "05/28",
      //   "05/28",
      //   "05/29",
      //   "05/30",
      // ],
    },
  };
  console.log("data", data);
  const series = [
    {
      name: "Chalet Deluxe",
      data: data.chaletDeluxeWeekArray,
      // data: [44, 55, 57, 56, 61, 55, 61],
    },
    {
      name: "Chalet Standard",
      data: data.chaletStandardWeekArray,
      // data: [44, 55, 57, 56, 61, 55, 61],
    },
    {
      name: "Park",
      data: data.parkWeekArray,
      // data: [76, 85, 101, 98, 87, 23, 41],
    },
  ];
  // const series = [
  //   {
  //     name: "series-1",
  //     data: [30, 40, 45, 50, 49, 60, 70, 91, 25, 10],
  //   },
  // ];
  return (
    <div className="col-span-12 md:col-span-6 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
      <div>
        <h3 className="text-xl font-semibold text-black dark:text-white">
          Booking Overview - Weekly
        </h3>
      </div>

      <div className="mb-2">
        <div id="chartFour" className="-ml-5">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height="400"
          />
        </div>
      </div>
    </div>
  );
}
