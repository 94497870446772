// import React from 'react';
// import img1 from '../../../assets/eddi-aguirre-ZAVHbpOn4Jk-unsplash@2x.png';
// import img2 from '../../../assets/li-yang-a8iCZvtrHpQ-unsplash@2x.png';
// import img3 from '../../../assets/bruno-van-der-kraan-i4rOpdj444c-unsplash@2x.png';

// export default function Package() {

//     return (
//         <div className='w-full h-auto flex flex-col pt-30 pb-50'>
//             <div className='lg:mx-28 md:mx-16 mx-12 xl:w-[42%] lg:w-[52%] w-auto mb-15 '>
//                 <p className='2xl:text-5xl lg:text-4xl md:text-4xl text-3xl font-felixTitling mb-4'>PACKAGES</p>
//                 <p className='lg:text-xl text-lg  font-poppins text-[#0d1316] font-light leading-7'>Duis ut egestas dolor. Sed vel tellus pulvinar, sodales diam viverra, luctus leo. Suspendisse dignissim nisl sit amet diam facilisis, ac scelerisque nulla dignissim.</p>
//             </div>
//             <div className='grid grid-cols-8 gap-3'>
//                 <div className='md:col-span-2  '>
//                     <div className='w-full h-[450px]'>
//                         <img src={img1} className='w-full h-full object-cover' />
//                     </div>
//                 </div>
//                 <div className='md:col-span-4 relative'>
//                     <div className='w-full h-[450px] '>
//                         <img src={img2} className='w-full h-full object-cover' />
//                         <div className='absolute left-5 bottom-4 w-[65%]'>
//                             <p className='w-auto px-[10%] text-[30px] font-normal font-felixTitling text-[#fff]'>PROIN A ERAT ID QUAMCSODALS POR NEC VITAE ORACI</p>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='md:col-span-2'>
//                     <div className='w-full h-[450px]'>
//                         <img src={img3} className='w-full h-full' />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

import React, { useEffect, useState } from "react";
import { HOME } from "../../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "./Package.css";
import { SLIDER } from "../../../api/slider";

export default function Package() {
  const slides = [
    {
      id: 0,
      className: "slide-width-201",
    },
    {
      id: 1,
      className: "slide-width-50",
    },
    {
      id: 2,
      className: "slide-width-20",
    },
    {
      id: 3,
      className: "slide-width-202",
    },
    {
      id: 4,
      className: "slide-width-202",
    },
    {
      id: 5,
      className: "slide-width-201",
    },
    {
      id: 6,
      className: "slide-width-50",
    },
    {
      id: 7,
      className: "slide-width-20",
    },
    {
      id: 8,
      className: "slide-width-50",
    },
    {
      id: 9,
      className: "slide-width-20",
    },
  ];

  // const slides = [
  //   {
  //     id: 0,
  //     img: HOME.PACKAGE_IMG,
  //     className: "slide-width-201",
  //   },
  //   {
  //     id: 1,
  //     img: HOME.PACKAGE_IMG2,
  //     className: "slide-width-50",
  //   },
  //   {
  //     id: 2,
  //     img: HOME.PACKAGE_IMG3,
  //     className: "slide-width-20",
  //   },
  //   {
  //     id: 3,
  //     img: HOME.PACKAGE_IMG4,
  //     className: "slide-width-202",
  //   },
  //   {
  //     id: 4,
  //     img: HOME.PACKAGE_IMG5,
  //     className: "slide-width-202",
  //   },
  //   {
  //     id: 5,
  //     img: HOME.PACKAGE_IMG6,
  //     className: "slide-width-201",
  //   },
  //   {
  //     id: 6,
  //     img: HOME.PACKAGE_IMG7,
  //     className: "slide-width-50",
  //   },
  //   {
  //     id: 7,
  //     img: HOME.PACKAGE_IMG8,
  //     className: "slide-width-20",
  //   },
  //   {
  //     id: 8,
  //     img: HOME.PACKAGE_IMG9,
  //     className: "slide-width-50",
  //   },
  //   {
  //     id: 9,
  //     img: HOME.PACKAGE_IMG10,
  //     className: "slide-width-20",
  //   },
  // ];

  const [session1Images, setSession1Images] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await SLIDER.GET_ALL();
        const baseURL = "/slider";

        const session1Urls = response.data.flatMap((image) =>
          (image.session1 || []).map((sessionOneImage) => ({
            ...image,
            session1: `${baseURL}/${sessionOneImage}`,
          }))
        );
        setSession1Images(session1Urls);
        setLoading(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);
  return (
    <>
      {!loading && (
        <div className="w-full h-auto flex flex-col pt-30 xl:pb-24 lg:pb-40 md:pb-20 pb-20">
          <div className="w-full h-auto flex justify-center items-center">
            <div className="w-[95%] md:w-[80%] h-auto max-w-screen-2xl">
              <div className=" xl:w-[50%] lg:w-[70%] w-auto mb-15">
                <p className="2xl:text-5xl lg:text-4xl md:text-4xl text-3xl font-felixTitling mb-4">
                  PACKAGES
                </p>
                <p className="lg:text-lg font-poppins text-[#0d1316] font-extralight leading-7">
                  We offer packages tailored to meet your every need. Choose
                  from our exclusive room packages across four categories:
                  Luxury Suites, Premium Rooms, Deluxe Rooms, and Executive
                  Rooms. Indulge in personalized Ayurveda Spa treatments with
                  bespoke wellness packages. For events and celebrations, our
                  banquet halls and spaces come with customized packages,
                  ensuring a flawless experience. Whether it's accommodation,
                  rejuvenation, or events, we have the perfect package for you.
                </p>
              </div>
            </div>
          </div>

          {/* Swiper for large screens */}
          <div className="w-full h-auto hidden lg:block">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={20}
              slidesPerView={3}
              autoplay={{ delay: 1000 }}
              centeredSlides={true}
              loop={true}
              breakpoints={{
                374: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                1000: { slidesPerView: 3 },
              }}
              className="mySwiper"
            >
              {/* {session1Images.map((slide) => (
                <SwiperSlide key={slide.id} className={slide.className}>
                  <img
                    src={slide.session1}
                    className="w-full h-[550px] object-cover"
                    alt={`slide-${slide.id}`}
                  />
                </SwiperSlide>
              ))} */}
              {session1Images.map((slideImage, index) => {
                // Find the corresponding slide className based on index
                const slideClassName =
                  slides.find((slide) => slide.id === index)?.className || "slide-width-201";

                return (
                  <SwiperSlide key={index} className={slideClassName}>
                    <img
                      src={slideImage.session1}
                      className="w-full h-[550px] object-cover"
                      alt={`slide-${index}`}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          {/* Swiper for small and medium screens */}
          <div className="w-full h-auto lg:hidden">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={15}
              slidesPerView={1}
              // autoplay={{ delay: 2000 }}
              // centeredSlides={true}
              // loop={true}
              className="mySwiper"
            >
              {session1Images.map((slide) => (
                <SwiperSlide key={slide.id}>
                  <img
                    src={slide.session1}
                    className="w-[100%] h-auto object-cover"
                    alt={`slide-${slide.id}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* <div className="w-full h-auto ">
        <Swiper
          modules={[Autoplay]}
          spaceBetween={20}
          slidesPerView={3}
          autoplay={{ delay: 1000 }}
          centeredSlides={true}
          loop={true}
          breakpoints={{
            374: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 3,
            },
          }}
          className="mySwiper"
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide.id} className={slide.className}>
              <img
                src={slide.img}
                className="w-full h-[550px] object-cover"
                alt={`slide-${slide.id}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div> */}
        </div>
      )}
    </>
  );
}
