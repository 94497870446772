export const MENU = {
  ONE_TOP: require("./1.jpg"),
  ONE_BTM: require("./2.jpg"),
  // TWO_TOP: require("./3.jpg"),
  TWO_TOP: require("./3main.jpg"),
  TWO_BTM: require("./4.jpg"),
  COMING_SOON: require("./comingsoon.jpg"),
};

export const HOME = {
  R_LOGO: require("./rusticlogo.png"),
  VIDEO: require("./video/video.mp4"),
  LOGO: require("./Group 66@2x.png"),
  LIST: require("./List@2x.png"),
  // INFO_IMG: require("./rhema-kallianpur-jbJ-_hw2yag-unsplash-1.png"),
  // INFO_IMG: require("./rustic-2a.jpg"),
  INFO_IMG: require("./rusticoffer3.jpg"),
  // INFO_IMG2: require("./stories--BqB2zptPQo-unsplash.png"),
  INFO_IMG2: require("./info22.jpg"),
  // INFO_IMG2: require("./rustic-1b.jpeg"),
  // BANNER_IMG: require("./manuel-moreno-DGa0LQ0yDPc-unsplash@2x.png"),
  BANNER_IMG: require("./rustic-3f.jpg"),
  // BANNER_IMG2: require("./christian-lambert-vmIWr0NnpCQ-unsplash@2x.png"),
  // BANNER_IMG2: require("./rustic-4c.jpg"),
  BANNER_IMG2: require("./banner2.jpg"),
  // PACKAGE_IMG: require("./eddi-aguirre-ZAVHbpOn4Jk-unsplash@2x.png"),
  // PACKAGE_IMG: require("./rustic-9b.jpg"),
  PACKAGE_IMG: require("./pkg1.jpg"),
  // PACKAGE_IMG2: require("./li-yang-a8iCZvtrHpQ-unsplash@2x.png"),
  PACKAGE_IMG3: require("./pkg2.jpg"),
  // PACKAGE_IMG3: require("./rustic-6a.jpg"),
  // PACKAGE_IMG3: require("./bruno-van-der-kraan-i4rOpdj444c-unsplash@2x.png"),
  // PACKAGE_IMG2: require("./rustic-8.jpg"),
  // PACKAGE_IMG4: require("./rustic-7b.jpg"),
  // PACKAGE_IMG5: require("./rustic-00.jpg"),
  PACKAGE_IMG2: require("./pkg3.jpg"),
  PACKAGE_IMG4: require("./pkg4.jpg"),
  PACKAGE_IMG5: require("./pkg5.jpg"),
  PACKAGE_IMG6: require("./pkg6.jpg"),
  PACKAGE_IMG7: require("./pkg7.jpg"),
  PACKAGE_IMG8: require("./pkg8.jpg"),
  PACKAGE_IMG9: require("./pkg9.jpg"),
  PACKAGE_IMG10: require("./pkga1.jpg"),

  // OFFERS_IMG: require("./rhema-kallianpur-jbJ-_hw2yag-unsplash@2x.png"),
  OFFERS_IMG: require("./rustic-5.jpg"),
  OFFERS_IMG2: require("./rusticoffer.jpg"),
  OFFERS_IMG3: require("./r9a.jpg"),
  OFFER_IMG4: require("./rusticoffer1.jpg"),
  OFFER_IMG5: require("./mrge.jpg"),
  OFFER_IMG6: require("./lux-room.jpg"),
  // BLOG_IMG: require("./blog1.png"),
  BLOG_IMG: require("./rusticblog1.jpg"),
  // BLOG_IMG2: require("./blog2.png"),
  BLOG_IMG2: require("./rusticblog2.jpg"),
  // BLOG_IMG3: require("./blog3.png"),
  BLOG_IMG3: require("./rusticblog3.jpg"),
};
export const IMG = {
  TERMS_IMG: require("./terms.jpg"),
  ACCO_IMG: require("./pkg1.jpg"),
  ACCO_IMG1: require("./pkg2.jpg"),
  ACCOM: require("./accomod.jpg"),
  SPA: require("./spa.jpg"),
  ACCO_IMG2: require("./pkg3.jpg"),
  ACCO_IMG3: require("./pkg4.jpg"),
  ACCO_IMG4: require("./acco.jpg"),
  ACCO_IMG5: require("./acco2.jpg"),
  ACCO_IMG6: require("./acco3.jpg"),
  ACT_YOGA: require("./yoga.jpg"),
  ACT_SWIM: require("./swim.jpg"),
  ACT_ANGLING: require("./angling.jpg"),
  ACT_BOAT: require("./boat.jpg"),
  ACT_PEDEL: require("./activity2.jpg"),
  ACT_PLAY: require("./activity1.jpg"),
  ACT_B: require("./pkg9.jpg"),
  RUS_AYUR: require("./ayur.jpg"),
  FAC_BANNER: require("./facilitybanner.jpg"),
  YOGA: require("./yoga.png"),
};

export const ICONS = {
  I_AC: require("../assets/icons/bedroom.png"),
  I_ATM: require("../assets/icons/credit-card.png"),
  I_AYUR: require("../assets/icons/g1719.png"),
  I_BC: require("../assets/icons/river.png"),
  I_BOARD: require("../assets/icons/meeting.png"),
  I_DRIVER: require("../assets/icons/chauffeur.png"),
  I_SE: require("../assets/icons/sightseeing.png"),
  I_BUSI: require("../assets/icons/g5223.png"),
  I_CUT: require("../assets/icons/g2085.png"),
  I_ISD: require("../assets/icons/international-call.png"),
  I_MB: require("../assets/icons/minibar.png"),
  I_PR: require("../assets/icons/smoking-area.png"),
  I_SAFE: require("../assets/icons/locker.png"),
  I_CONF: require("../assets/icons/conference.png"),
  I_SWIM: require("../assets/icons/swim.png"),
  I_PARK: require("../assets/icons/park (2).png"),
  I_RECEP: require("../assets/icons/receptionist.png"),
  I_ROOM: require("../assets/icons/room-service.png"),
  I_RS: require("../assets/icons/rupee.png"),
  I_LAUN: require("../assets/icons/laundry-machine.png"),
  I_DOCT: require("../assets/icons/medical.png"),
  I_WIFI: require("../assets/icons/wifi-signal.png"),
  I_SIGHT: require("../assets/icons/bus-driver.png"),
  I_CHECK: require("../assets/icons/check-in.png"),

  Email: require("../assets/icons/email.png"),
  Address: require("../assets/icons/placeholder.png"),
  Phone: require("../assets/icons/phone.png"),
};
export const GALLERYIMAGE = {
  GAL_B: require("./gallery.jpg"),
  GAL_1: require("./panchakarma.jpg"),
  GAL_2: require("./yoga.jpg"),
  GAL_3: require("./swim.jpg"),
  GAL_4: require("./pkga1.jpg"),
  GAL_5: require("./g01.jpg"),
  GAL_6: require("./activity2.jpg"),
  GAL_7: require("./gal-13.jpg"),
  GAL_8: require("./pkg9.jpg"),
  GAL_9: require("./rusticblog1.jpg"),
  GAL_10: require("./g1.jpg"),
  GAL_11: require("./g2.jpg"),
  GAL_12: require("./g3.jpg"),
  GAL_13: require("./g4.jpg"),
  GAL_14: require("./g5.jpg"),
  GAL_15: require("./g6.jpg"),
  GAL_16: require("./g7.jpg"),
  GAL_17: require("./g8.jpg"),
  GAL_18: require("./g9.jpg"),
  GAL_19: require("./g11.jpg"),
  GAL_20: require("./g12.jpg"),
};
