import React, { useEffect, useState } from "react";
import { IMG } from "../../assets";
import { AYURVEDHA } from "../../api/ayurvedha";

export default function Ayurvedhalist() {
  useEffect(() => {
    window.scrollTo(0, 0); // Ensures the page scrolls to the top on mount
  }, []);
  // const data =[
  //     {
  //       "treatment": "Abhyanga (Oil Massage)",
  //       "price": "INR 2,500",
  //       "duration": "60 minutes"
  //     },
  //     {
  //       "treatment": "Navarakkizhi (Rice Massage)",
  //       "price": "INR 2,500",
  //       "duration": "60 minutes"
  //     },
  //     {
  //       "treatment": "Podikizhi (Bundle Massage)",
  //       "price": "INR 2,200",
  //       "duration": "60 minutes"
  //     },
  //     {
  //       "treatment": "Sirodhara (Medicinal Head Bath)",
  //       "price": "INR 3,000",
  //       "duration": "60 minutes"
  //     },
  //     {
  //       "treatment": "Pizhichil (Medicinal Body Bath)",
  //       "price": "INR 3,800",
  //       "duration": "60 minutes"
  //     },
  //     {
  //       "treatment": "Mukha Souvaryam (Ayurveda Facial)",
  //       "price": "INR 1,000",
  //       "duration": "45 minutes"
  //     },
  //     {
  //       "treatment": "Nasyam (Nisal Instillation)",
  //       "price": "INR 400",
  //       "duration": "20 minutes"
  //     },
  //     {
  //       "treatment": "Siroabhyanga (Head Massage)",
  //       "price": "INR 700",
  //       "duration": "30 minutes"
  //     },
  //     {
  //       "treatment": "Dhanyamadhara (Decoction Bath)",
  //       "price": "INR 1,800",
  //       "duration": "60 minutes"
  //     },
  //     {
  //       "treatment": "Udvarthanam (Powder Massage)",
  //       "price": "INR 1,800",
  //       "duration": "60 minutes"
  //     },
  //     {
  //       "treatment": "Gambheera Abhyanga (Deep Tissue Massage)",
  //       "price": "INR 3,000",
  //       "duration": "90 minutes"
  //     },
  //     {
  //       "treatment": "Udanchana (Lymphatic Drainage)",
  //       "price": "INR 3,000",
  //       "duration": "90 minutes"
  //     },
  //     {
  //       "treatment": "Tapta Pashana Abhyanga (Hot Stone Massage)",
  //       "price": "INR 3,000",
  //       "duration": "90 minutes"
  //     },
  //     {
  //       "treatment": "Pada Marmabhyanga (Foot Reflexology)",
  //       "price": "INR 1,500",
  //       "duration": "45 minutes"
  //     },
  //     {
  //       "treatment": "Ardha Abhyanga (Half Body Massage)",
  //       "price": "INR 1,000",
  //       "duration": "30 minutes"
  //     },
  //     {
  //       "treatment": "Sneha Vasti (Oil Retention)",
  //       "price": "INR 1,000",
  //       "duration": "30 minutes"
  //     },
  //     {
  //       "treatment": "Kashaya Vasti (Decoction Retention)",
  //       "price": "INR 1,500",
  //       "duration": "60 minutes"
  //     },
  //     {
  //       "treatment": "Sirovasthi (Oil Retention on Head)",
  //       "price": "INR 1,000",
  //       "duration": "60 minutes"
  //     }
  //   ]
  const [ayurvedha, setAyurvedha] = useState([]);

  useEffect(() => {
    const fetchAyurvedha = async () => {
      try {
        const response = await AYURVEDHA.GET_ALL();
        console.log("response", response);
        setAyurvedha(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAyurvedha();
  }, []);

  return (
    <div className="h-auto w-full">
      <div>
        <div className=" bg-gren w-full h-full lg:h-150 md:h-100">
          <img
            src={IMG.RUS_AYUR}
            alt="images"
            style={{ width: "100%", height: "100%" }}
            className=" object-cover"
          />
        </div>
        <div className="lg:px-[150px] md:px-20 pt-20 w-full">
          <div className="overflow-x-auto bg-gren p-3">
            <table className="min-w-full table-auto border-collapse">
              <thead>
                <tr className="bg-[#36454F] text-[#FFF]">
                  <th className="px-4 py-2 border-2 text-lg">Service</th>
                  <th className="px-4 py-2 border-2 text-lg">Price</th>
                </tr>
              </thead>
              <tbody>
                {ayurvedha.map((data) => (
                  <tr>
                    <td className="px-4 py-2 border-2 border-[#fff] bg-[#E5E4E2]">
                      <p className="text-lg">
                        {data.title} ({data.type})
                      </p>
                      <p className="text-sm italic">{data.duration} minutes</p>
                    </td>
                    <td className="md:px-4 px-2 py-2 border-2 border-[#fff] bg-[#E5E4E2] whitespace-nowrap">
                      INR{" "}
                      {new Intl.NumberFormat("en-IN").format(
                        Number(data.price)
                      )}
                      /- {/* INR {data.price}/-  */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
