import React, { useEffect, useState } from "react";
import { IMG } from "../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import "./Ayurvedha.css";
import { AYURVEDHA } from "../../api/ayurvedha";

export default function Ayurvedha() {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  // const array2 = [
  //   {
  //     id: 1,
  //     title1: "ABHYANGA",
  //     title2: "(OIL MASSGE)",
  //     dur: "Duration",
  //     min: "60 Minutes",
  //     price: "Price",
  //     txt: "Double [Per Day]",
  //     singleinr: "INR ",
  //     pay: "17,888",
  //   },
  //   {
  //     id: 2,
  //     title1: "NAVARAKKIZHI",
  //     title2: "(RICE MASSAGE)",
  //     dur: "Duration",
  //     min: "60 Minutes",
  //     price: "Price",
  //     txt: "Double [Per Day]",
  //     singleinr: "INR ",
  //     pay: "17,888",
  //   },
  //   {
  //     id: 3,
  //     title1: "PODIKKIZHI",
  //     title2: "(BUNDLE MASSAGE)",
  //     dur: "Duration",
  //     min: "60 Minutes",
  //     price: "Price",
  //     txt: "Double [Per Day]",
  //     singleinr: "INR ",
  //     pay: "17,888",
  //   },
  //   {
  //     id: 4,
  //     title1: "SIRODHARA",
  //     title2: "(MEDICAL HEAD BATH)",
  //     dur: "Duration",
  //     min: "60 Minutes",
  //     price: "Price",
  //     txt: "Double [Per Day]",
  //     singleinr: "INR ",
  //     pay: "17,888",
  //   },
  //   {
  //     id: 5,
  //     title1: "NAVARAKKIZHI",
  //     title2: "(RICE MASSAGE)",
  //     dur: "Duration",
  //     min: "60 Minutes",
  //     price: "Price",
  //     txt: "Double [Per Day]",
  //     singleinr: "INR ",
  //     pay: "17,888",
  //   },
  //   {
  //     id: 6,
  //     title1: "NAVARAKKIZHI",
  //     title2: "(RICE MASSAGE)",
  //     dur: "Duration",
  //     min: "60 Minutes",
  //     price: "Price",
  //     txt: "Double [Per Day]",
  //     singleinr: "INR ",
  //     pay: "17,888",
  //   },
  // ]
  const [ayurvedha, setAyurvedha] = useState([]);

  useEffect(() => {
    const fetchAyurvedha = async () => {
      try {
        const response = await AYURVEDHA.GET_ALL();
        console.log("response", response);
        setAyurvedha(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAyurvedha();
  }, []);

  useEffect(() => {
    console.log("Updated ayurvedha", ayurvedha);
  }, [ayurvedha]);
  return (
    <div className="w-full h-auto flex flex-col pb-10">
      {/* <div className="w-full bg-green h-auto "> */}
      <div className="h-auto w-auto relative">
        <div className="w-full h-full md:h-screen">
          <img
            src={IMG.SPA}
            alt="images"
            style={{ width: "100%", height: "100%" }}
            className="object-cover"
          />
        </div>
        <div className="absolute bottom-[25%] pb-0 xl:pb-0  text-white w-full px-5 lg:px-20 text-center flex flex-col gap-5 md:gap-20 ">
          {/* <motion.p
            initial="hidden"
            whileInView="reveal"
            transition={{ staggerChildren: 0.02 }}
            className="font-Dalmatins leading-[70px] lg:leading-[90px] text-xl  lg:text-4xl">
            <motion.span
              transition={{ duration: 0.8 }}
            >
              Ayurveda Packages
            </motion.span>
          </motion.p> */}
          <motion.p
            initial="hidden"
            whileInView="reveal"
            transition={{ staggerChildren: 0.02 }}
            className="font-Dalmatins leading-[70px] lg:leading-[90px] text-xl lg:text-4xl text-white" // Set the primary color for the text
            style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }} // Adding outline via text-shadow
          >
            <motion.span transition={{ duration: 0.8 }}>
              Ayurveda Packages
            </motion.span>
          </motion.p>
        </div>
      </div>
      <div className=' bg-white w-auto h-auto flex justify-center items-center md:pt-18 md:pb-8 pt-12 pb-6'>
        <div className='xl:w-[70%] lg:w-[60%] md:w-[70%] px-1 md:px-0 bg-re'>
          <p className='2xl:text-[40px] lg:text-[40px] md:text-[46px] text-[35px] font-felixTitling font-light text-center pb-5'>TRADITIONAL AYURVEDA AT RUSTIC LEISURES</p>
          <p className='font-poppins xl:text-[25px] 2xl:text-[25px] text-[15px] lg:text-[22px] md:text-[19px] text-center font-extralight px-2'>At Rustic Leisures, we invite you to embark on a transformative journey into Ayurveda, where
            traditional practices meet modern wellness. Our Ayurvedic spa and treatments in a serene and
            hygienic environment designed for your comfort and privacy, ensure a rejuvenating experience
            tailored to your needs. Whether you are a tourist, a wellness enthusiast, or a traveler, seeking
            Ayurvedic wellness, Rustic Leisures is your dream destination in Kochi. </p>

        </div>
      </div>

      <div className="bg-bue w-full h-full">
        <div className=" bg-re w-auto h-auto flex justify-center items-center pt-8 pb-15 bg-rd">
          <div className='w-auto bg-gren xl:w-[50%] lg:w-[50%] md:w-[70%] px-1 md:px-0'>
            <p className='2xl:text-[59px] lg:text-[50px] md:text-[46px] text-[35px] font-felixTitling font-light text-center pb-4'>Ayurveda Packages</p>
            <p className='font-poppins xl:text-[25px] 2xl:text-[25px] text-[15px] lg:text-[22px] md:text-[19px] text-center font-extralight'>Our curated Ayurvedic packages,
              enhance relaxation, detoxification,
              stress relief and overall wellness. </p>
          </div>
        </div>
        <div className="bg-re w-full h-full flex justify-center items-center">
          <div className="h-auto w-[80%] bg-yelow  flex items-center justify-center pb-20">
            <Swiper
              style={{
                "--swiper-navigation-color": "#7B899C",
              }}
              spaceBetween={20}
              loop={true}
              speed={1000}
              preloadImages={false}
              // watchSlidesVisibility={true}
              watchOverflow={true}
              // autoplay={{
              //   delay: 3000,
              //   disableOnInteraction: false,
              // }}
              pagination={{
                clickable: true, // Dots will be clickable
              }}
              breakpoints={{
                374: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 4,
                },
              }}
              grabCursor={true}
              modules={[Autoplay, Pagination]}
              className="mySwiper custom-pagination"
            >
              {ayurvedha.map((data, index) => (
                <SwiperSlide key={index}>
                  <div className="bg-r w-full h-full flex justify-center items-center pt-10 pb-7">
                    {/* Here’s the grid for each SwiperSlide content */}
                    <div className="w-full bg-blu p-4 ">
                      {/* Each individual data item */}
                      <div className="bg-[#F1F9FF] relative rounded-md shadow-md ">
                        <div className="absolute  -top-[56px] flex justify-center bg-rd items-center w-full">
                          <div className="rounded-full bg-gree p-5 bg-white border-2 border-[#BCE0FD] flex justify-center w-auto h-full">
                            <img
                              src={IMG.YOGA}
                              alt=""
                              srcset=""
                              className="object-cover   w-12 h-12 "
                            />
                          </div>
                        </div>
                        {/* <div className='px-[20%]'> */}
                        <div className=" text-center pt-12 pb-5">
                          <p className="font-felixTitling text-[24px] truncate overflow-hidden whitespace-nowrap text-center px-[3%]">
                            {data.title}
                            <br />
                            <span className="text-[16px]">{data.type}</span>
                          </p>
                          {/* <p className="font-felixTitling text-[24px] ">
                            {data.title}
                            <br />
                            <span className="text-[16px]">{data.type}</span>
                          </p> */}
                        </div>
                        <div className="px-[25%]">
                          <hr className="w-full text-[#BCE0FD] " />
                        </div>
                        <div className="py-4">
                          <p className="text-center font-poppins text-[18px] font-light">
                            Duration
                            <br />
                            <span className="text-[#A98445] text-[25px] font-extrabold font-poppins">
                              {data.duration} Minutes
                            </span>
                          </p>
                        </div>
                        <div className="px-[25%]">
                          <hr className="w-full text-[#BCE0FD] " />
                        </div>
                        <div className="py-3 text-center">
                          <p className="font-poppins text-[20px]">
                            Price
                            <br />
                            <span className="font-bold text-[24px]">
                              INR
                            </span>{" "}
                            <span className="text-[#A98445] text-[25px] font-extrabold font-poppins">
                              {/* {data.price} */}
                              {new Intl.NumberFormat('en-IN').format(Number(data.price))}/-
                            </span>
                          </p>
                        </div>

                        <div className=" w-full h-auto flex justify-center items-center py-8">
                          <button className="bg-[#A98445] p-3 w-[55%] rounded-md 2xl:text-[18px] md:text-[14px] font-felixTitling font-light text-white">
                            Book Now
                          </button>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="bg-ed w-full h-full flex justify-center items-center">
          <Link
            to="/ayurvedhalist"
            className="bg-[#A98445] p-3 xl:w-[20%] w-[60%] md:w-[40%] lg:w-[30%] 2xl:text:w-[20%] text-center rounded-md xl:text-[20px] text-[15px] font-felixTitling font-light text-white"
          >
            VIEW MORE
          </Link>
        </div>
      </div>
    </div>
  );
}
