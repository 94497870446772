import React, { useEffect } from "react";
import { HOME } from "../../../assets";
// import { motion, Variants } from "framer-motion";
import splitStringUsingRegex from "../../../utils/splitStringUsingRegex";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function InfoSection() {
  const controls = useAnimation();
  const [viewRef, inView] = useInView({
    triggerOnce: false, // Allows re-triggering the animation when it comes into view again
    threshold: 0.2, // Adjust this value to control when the animation starts
  }); //   const heading = "Welcome to Leisures of Luxury and Relaxation";
  //   const textdata =
  //     " Step into Rustic Leisures, where luxury, business, and leisure blend seamlessly. Enjoy an exceptional experience that promises comfort and rejuvenation, catering to all your needs, whether you're here for holidays, travel, or work";
  //   const headingChars = splitStringUsingRegex(heading);
  //   const textDataChars = splitStringUsingRegex(textdata);
  //   const heading2 = "Exquisite Dining for Every Occasion";
  //   const textdata2 = " Enjoy a fine dining experience at our hotel, where each meal is a celebration of refined flavors and elegance, perfectly complementing your stay with us"
  //   const headingChars2 = splitStringUsingRegex(heading2);
  //   const textDataChars2 = splitStringUsingRegex(textdata2);

  const array = [
    {
      id: 1,
      heading: "Welcome to Leisures of Luxury and Relaxation",
      heading2: "Exquisite Dining for Every Occasion",
      textdata:
        " Step into Rustic Leisures, where luxury, business, and leisure blend seamlessly. Enjoy an exceptional experience that promises comfort and rejuvenation, catering to all your needs, whether you're here for holidays, travel, or work",
      textdata2:
        " Enjoy a fine dining experience at our hotel, where each meal is a celebration of refined flavors and elegance, perfectly complementing your stay with us",
    },
  ];

  const charVariants = {
    hidden: { opacity: 0, x: 800 }, // Adjust animation properties as needed
    reveal: { opacity: 1, x: 0 },
  };

  const animationVariants1 = {
    hidden: { opacity: 0, scale: 0.5, x: 500 },
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 1.25 } },
  };
  const animationVariants = {
    hidden: { opacity: 0, scale: 0.5, x: -500 }, // Start from the left
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 1.25 } }, // End at the center
  };
  const animationVariants3 = {
    hidden: { opacity: 0, scale: 0.5, x: -500 },
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 1.5 } },
  };
  const animationVariant4 = {
    hidden: { opacity: 0, scale: 0.5, x: 500 }, // Start from the left
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 1.5 } }, // End at the center
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
      controls.start("textShow");
    } else {
      controls.start("textHide");
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <div
      ref={viewRef}
      className="w-full h-full flex justify-center items-center px-[20px] sm:px-[50px] lg:py-[4%] px-[50px] xl:px-[90px] 2xl:px-[90px] overflow-hidden"
    >
      <div className="w-[95%] h-full max-w-screen-2xl py-5 sm:py-0 flex flex-col gap-0 sm:gap-10 pt-5 lg:flex-row gap-5 ">
        <div className="w-full grid lg:grid-rows-5 gap-10 sm:gap-0 lg:gap-10">
          <motion.div
            // initial="hidden"
            // animate={controls}
            // variants={animationVariants3}
            className="lg:row-span-2 w-full flex flex-col gap-2 pt-0 sm:pt-15 lg:pt-15 w-[95%] xl:w-[90%] pt-20 2xl:pt-30 lg:pb-20 md:pb-15"
          >
            <motion.p
              initial="hidden"
              whileInView="reveal"
              transition={{ staggerChildren: 0.02 }}
              className="lg:text-4xl text-3xl font-felixTitling "
            >
              {array.map((char, index) => (
                <motion.span
                  key={index}
                  variants={charVariants}
                  transition={{ duration: 0.5 }}
                >
                  {char.heading2}
                </motion.span>
              ))}
            </motion.p>
            <motion.p
              initial="hidden"
              whileInView="reveal"
              transition={{ staggerChildren: 0.02 }}
              className="md:text-2xl lg:text-xl text-lg font-poppins text-[#0d1316] font-extralight leading-7"
            >
              {array.map((char, index) => (
                <motion.span
                  key={index}
                  variants={charVariants}
                  transition={{ duration: 0.5 }}
                >
                  {char.textdata2}
                </motion.span>
              ))}
            </motion.p>
          </motion.div>
          <div className="row-span-3 w-full flex justify-center md:justify-start">
            <motion.div className="w-full lg:w-[95%] xl:w-[90%] md:h-[650px] h-[400px]">
              {/* <div className="w-full lg:w-[95%] xl:w-[90%] md:h-[570px] h-[350px]"> */}
              <motion.img
                initial="hidden"
                animate={controls}
                variants={animationVariants}
                // whileHover={{ scale: 1.02 }}
                src={HOME.INFO_IMG}
                className="w-full h-full object-cover"
              />
            </motion.div>
          </div>
        </div>
        <div className="w-full grid md:grid-rows-5 sm:gap-10 lg:mt-0 md:mt-8">
          <div className=" row-span-3 w-full flex justify-center md:justify-end">
            <motion.div className="w-full lg:w-[95%] xl:w-[90%] 2xl:[100%] md:h-[650px] h-[400px]">
              {/* <div className="w-full lg:w-[95%] xl:w-[90%] 2xl:[100%] md:h-[570px] h-[350px]"> */}
              <motion.img
                initial="hidden"
                animate={controls}
                variants={animationVariants1}
                // whileHover={{ scale: 1.03 }}
                src={HOME.INFO_IMG2}
                className="w-full h-full object-cover"
              />
            </motion.div>
          </div>
          <div className="row-span-2 w-full flex justify-center md:justify-end ">
            <motion.div
              // initial="hidden"
              // animate={controls}
              // variants={animationVariant4}
              className="w-full flex flex-col gap-2 pb-5 sm:pb-0 pt-10 lg:w-[95%]  2xl:[100%] xl:w-[90%] "
            >
              <motion.p
                initial="hidden"
                whileInView="reveal"
                transition={{ staggerChildren: 0.02 }}
                className="lg:text-4xl text-3xl font-felixTitling"
              >
                {array.map((char, index) => (
                  <motion.span
                    key={index}
                    variants={charVariants}
                    transition={{ duration: 0.5 }}
                  >
                    {char.heading}
                  </motion.span>
                ))}
              </motion.p>
              <motion.p
                initial="hidden"
                whileInView="reveal"
                transition={{ staggerChildren: 0.02 }}
                className="md:text-2xl lg:text-xl text-lg font-poppins text-[#0d1316] font-extralight leading-7"
              >
                {array.map((char, index) => (
                  <motion.span
                    key={index}
                    variants={charVariants}
                    transition={{ duration: 0.5 }}
                  >
                    {char.textdata}
                  </motion.span>
                ))}
              </motion.p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
