import React from "react";
import ReactApexChart from "react-apexcharts";

export default function DonutChart({ count }) {
  console.log("count", count);
  const options = {
    labels: [
      "Chalet Deluxe",
      "Chalet Standard",
      "Park",
      "Free with Deluxe",
      "Free with Standard",
    ],
  };
  const series = [
    count.chaletDeluxeCount,
    count.chaletStandardCount,
    count.parkCount,
    count.freeDeluxeCount,
    count.freeStandardCount,
  ];
  return (
    <div className="col-span-12 md:col-span-6 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
      <div>
        <h3 className="text-xl font-semibold text-black dark:text-white">
          Booking Overview - Today
        </h3>
      </div>

      <div className="mb-2">
        <div id="chartFour" className="-ml-5">
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            width="500"
          />
        </div>
      </div>
    </div>
  );
}
