import React, { useEffect, useState } from "react";
import { HOME } from "../../../assets";
import {BLOGS} from "../../../api/blogs";

export default function Blog() {
  // const blogArray = [
  //   {
  //     id: 1,
  //     img: HOME.BLOG_IMG,
  //     txt1: "05 DEC 2023 VIVERRA, LUCTUS LEO",
  //     txt2: "PROIN A ERAT ID QUAM SODALES POR NEC VITAE ORCI",
  //     txt3: " Nam rutrum semper turpis aciaculis interdum et malesudada fames acante ipsum",
  //   },
  //   {
  //     id: 2,
  //     img: HOME.BLOG_IMG2,
  //     txt1: "05 DEC 2023 VIVERRA, LUCTUS LEO",
  //     txt2: "PROIN A ERAT ID QUAM SODALES POR NEC VITAE ORCI",
  //     txt3: " Nam rutrum semper turpis aciaculis interdum et malesudada fames acante ipsum",
  //   },
  //   {
  //     id: 2,
  //     img: HOME.BLOG_IMG3,
  //     txt1: "05 DEC 2023 VIVERRA, LUCTUS LEO",
  //     txt2: "PROIN A ERAT ID QUAM SODALES POR NEC VITAE ORCI",
  //     txt3: " Nam rutrum semper turpis aciaculis interdum et malesudada fames acante ipsum",
  //   },
  // ];
  const [blogs, setBlogs] = useState([]);

  // useEffect(() => {
  //   const fetchBlogs = async () => {
  //     try {
  //       const response = await BLOGS.GET_ALL();
  //       console.log("response", response);
  //       const baseURL = "/blog";
  //       setBlogs(response.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   fetchBlogs();
  // }, []);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await BLOGS.GET_ALL();
        console.log("response", response);
  
        const baseURL = "/blog"; // Base URL for blog images
  
        const updatedBlogs = response.data.map((blog) => ({
          ...blog,
          blogImage: `${baseURL}/${blog.blogImage}`, // Prepend '/blog' to the blogImage path
        }));
  
        setBlogs(updatedBlogs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchBlogs();
  }, []);
  
  useEffect(() => {
    console.log("Updated blogs", blogs);
  }, [blogs]);
  return (
    <div className="w-full h-full flex justify-center items-center ">
      <div className="h-full w-[95%] md:w-[80%] max-w-screen-2xl flex flex-col gap-5 lg:pb-40 pb-30  pt-10 border-b-2 border-[#E5E5E5]">
        <p className="2xl:text-5xl lg:text-4xl md:text-4xl text-3xl text-[#0D1316] font-felixTitling">
          BLOGS
        </p>
        <div className=" text-center flex flex-col max-w-screen-2xl justify-center lg:grid grid-cols-3 gap-20">
          {blogs.map((data) => (
            <div className="flex flex-col gap-3 lg:col-span-1 ">
              <img
                src={data.blogImage}
                className="w-full h-auto  object-cover aspect-square"
              />
              <p className="text-sm font-poppins">{data.auther} </p>
              <p className=" text-2xl font-felixTitling truncate overflow-hidden whitespace-nowrap text-center">{data.title} </p>
              <p className="lg:text-xl text-lg font-poppins text-[#0d1316] font-extralight leading-7 px-[2%]">
                {data.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
