
import React, { useEffect, useState } from 'react';
import { ICONS } from "../../../assets";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { COMPANYCONTACT } from "../../../api/companycontact"
import { ErrorMessage } from "formik";

export default function CompContact() {
    
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({_id:"", address: "", telephone: "", email: "" });
    const [contactId, setContactId] = useState("")
    
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchInitialContact();
    }, []);

    
    const fetchInitialContact = async () => {
        
        try {
            const res = await COMPANYCONTACT.GET_ALL();
            if (res.status === 200 ) {
               
                const firstContact = res.data[0]
               
                setInitialValues({
                    _id: firstContact._id,
                    address: firstContact.address,
                    telephone: firstContact.telephone,
                    email: firstContact.email,
                });
                //toast(JSON.stringify (initialValues))
                setContactId(firstContact._id);  
                         
            } else {
                toast.error("No contacts found.");
            }
        } catch (error) {
            console.error('Failed to fetch contacts:', error);
            toast.error("Failed to fetch contacts. Please try again later.");
        }
    };


    const validationSchema = Yup.object().shape({
        address: Yup.string().required("Required"),
        telephone: Yup.number().required("Required").min(8, "Too Short!"),
        email: Yup.string().required("Required").email("Invalid email"),
    });

    const handleSubmit = async (values, { resetForm }) => {
        
        setLoading(true);
        try {
          
        
            const res = await COMPANYCONTACT.UPDATE(values, contactId); 
            
            setLoading(false);
            if (res.status === 200) {
                toast.success("address changed successfully!");
                resetForm();
            } else {
                toast.error(res);
            }
        } catch (error) {
            setLoading(false);
            console.error('Failed to send message:', error);
            toast.error("Failed to send message. Please try again later.");
        }
    };

    return (
        <div className='h-auto w-full'>
            <div className='flex flex-col'>
                

                <div className="w-full h-full items-center justify-center py-10">
                    <div className="flex flex-col gap-3 pb-10">
                        <p className="text-3xl font-felixTitling text-[#000] text-center">Contact Us</p>
                        <p className="text-lg font-poppins text-[#000] text-center">“We listen, we care, we deliver.”</p>
                    </div>
                    <div className="px-[20px] md:px-[100px] grid md:grid-cols-2 gap-10 text-[#000]">
                        <div className="flex flex-col gap-5 text-[#000] pt-10">
                            {/* Contact Info */}
                            <div className="flex flex-row gap-3 ">
                                <div className="w-[25px] h-[25px]">
                                    <img src={ICONS.Address} className="w-full h-full object-over" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <p className="font-bold text-sm font-poppins">Address:</p>
                                    <p className="text-sm font-poppins">{initialValues.address}</p>
                                </div>
                            </div>
                            <div className="flex flex-row gap-3 text-[#000]">
                                <div className="w-[25px] h-[25px]">
                                    <img src={ICONS.Phone} className="w-full h-full object-over" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <p className="font-bold text-sm font-poppins">Phone:</p>
                                    <p className="text-sm font-poppins">{initialValues.telephone}</p>
                                </div>
                            </div>
                            <div className="flex flex-row gap-3 text-[#000]">
                                <div className="w-[25px] h-[25px]">
                                    <img src={ICONS.Email} className="w-full h-full object-cover" />
                                </div>
                                <div className="flex flex-col gap-2 text-[#000]">
                                    <p className="font-bold text-sm font-poppins">Email:</p>
                                    <p className="text-sm font-poppins">{initialValues.email}</p>
                                </div>
                            </div>
                        </div>
                        <Formik
                            initialValues={{ address: "", telephone: "", email: "" }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <div className="bg-gray p-6 rounded-lg shadow-md">
                                    <h3 className="text-xl font-semibold mb-4 text-center">Update Address</h3>
                                    <Form className="space-y-4">
                                        <div>
                                            <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
                                            <Field
                                                type="text"
                                                id="address"
                                                name="address"
                                                className="mt-1 block w-full px-4 py-2 border border-gray outline-none rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                placeholder="New address to update"
                                            />
                                            <ErrorMessage name="address" component="div" className="text-red-600" />
                                        </div>
                                        <div>
                                            <label htmlFor="telephone" className="block text-sm font-medium text-gray-700">telephone</label>
                                            <Field
                                                type="number"
                                                id="telephone"
                                                name="telephone"
                                                className="mt-1 block w-full px-4 py-2 border border-gray outline-none rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                placeholder="new telephone number to update"
                                            />
                                            <ErrorMessage name="telephone" component="div" className="text-red-600" />
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                                            <Field
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="mt-1 block w-full px-4 py-2 border border-gray outline-none rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                            <ErrorMessage name="email" component="div" className="text-red-600" />
                                        </div>
                                        
                                        <button 
                                            type="submit" 
                                            disabled={isSubmitting} 
                                            className="w-full py-2 px-4 bg-blue text-white font-bold rounded-md hover:bg-teal-600"
                                        >
                                            {loading ? "Changing..." : "Change"}
                                        </button>
                                    </Form>
                                </div>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
