// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-pagination .swiper-pagination {
    bottom: 5px !important;
    display: flex;
    justify-content: center;
    width: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/pages/ayurvedha/Ayurvedha.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,uBAAuB;IACvB,WAAW;EACb","sourcesContent":[".custom-pagination .swiper-pagination {\r\n    bottom: 5px !important;\r\n    display: flex;\r\n    justify-content: center;\r\n    width: 100%;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
