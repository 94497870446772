import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const AYURVEDHA = {
    ADD(req) {
        const url = `${CONSTANT.URL}ayurvedha`;
        return API_REQUEST("POST", url, req);
    },
    UPDATE(req, id, token) {
        const url = `${CONSTANT.URL}ayurvedha/${id}`;
        return API_REQUEST("PUT", url, req, {
            Authorization: `Bearer ${token}`,
        });
    },
    DELETE(req, id, token) {
        const url = `${CONSTANT.URL}ayurvedha/delete/${id}`;
        return API_REQUEST("PUT", url, req, {
            Authorization: `Bearer ${token}`,
        });
    },
    GET_ALL() {
        const url = `${CONSTANT.URL}ayurvedha`;
        return API_REQUEST("GET", url);
    },
    GET_BY_ID(id) {
        const url = `${CONSTANT.URL}ayurvedha/${id}`;
        return API_REQUEST("GET", url);
    },
};
