import { useState, useEffect, useContext } from "react";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { SLIDER } from "../../../api/slider";
import { CONSTANT } from "../../../constant/Constant";
import { toast } from "react-toastify";
import { AuthContext } from "../../../utils/AuthContext";
import { UseAuth } from "../../../utils/UseAuth";

export default function SliderManage() {
  const [data, setData] = useState({
    session1: [],
  });
  const { checkAccessTokenValidity } = UseAuth();
  const { token } = useContext(AuthContext);
  const [removedImages] = useState([]);
  const [imageList1, setImageList1] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [id, setId] = useState("");
  const getSlider = async () => {
    try {
      const res = await SLIDER.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch events information");
      }
      setId(res.data[0]._id);
      setData({
        ...data,
        session1: res.data[0].session1,
      });
      var array1 = [];
      res.data[0].session1.map((data) => {
        array1.push(`${CONSTANT.BASE_URL}/slider/${data}`);
        return array1;
      });

      setImageList1(array1);
      data.session1 = res.data[0].session1;
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  const showImageList1 = (files, index) => {
    const imgArray = imageList1;
    const realArray = data.session1;
    for (let i = 0; i < files.length; i++) {
      const fileLoaded = URL.createObjectURL(files[i]);
      imgArray.push(fileLoaded);
      realArray.push(files[i]);
    }
    setData({
      ...data,
      session1: realArray,
    });
    setImageList1(imgArray);
  };

  const updateData = async (formData, id) => {
    try {
      console.log("formDatain update api", formData);
      const res = await SLIDER.UPDATE(formData, id, token);
      setLoading(false);
      console.log("res", res);
      if (res.status === 200) {
        toast.success("Updated slider");
        // navigate("/manage-slider");
      } else if (res.status === 201) {
        toast.info("No changes added");
      } else {
        toast.error("Failed to update slider");
        // throw new Error("Failed to update slider");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating slider: ", error);
      if (error.response && error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(formData);
        setId(id);
        setRefresh(!refresh);
      } else {
        toast.error("Failed to update slide");
      }
    }
  };

  const updateSlides = async () => {
    console.log("data.session1", data.session1);
    if (data.session1.length >= 2) {
      const formData = new FormData();
      for (let i = 0; i < data.session1.length; i++) {
        formData.append("session1", data.session1[i]);
      }
      formData.append("removedImages", JSON.stringify(removedImages));
      // console.log("removedImages", removedImages);
      await updateData(formData, id);
    } else {
      toast.error("At least two slides are required!");
    }
  };

  useEffect(() => {
    getSlider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reRunData !== null) updateData(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <Breadcrumbs pageName="Manage Slides" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex flex-col gap-9 h-auto">
          <div className="p-6.5 h-auto">
            <div className="mb-4.5 ">
              <label className="mb-2.5 block text-black dark:text-white">
                Slider
              </label>

              <div className="flex items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file1"
                  className="flex flex-col items-center justify-center w-full h-50 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-50 hover:bg-gray dark:border-gray dark:hover:border-gray dark:hover:bg-gray"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">
                        Click to upload photos
                      </span>
                      {/* or drag and drop */}
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      PNG, JPG or JPEG (MAX. 800x400px)
                    </p>
                  </div>
                  <input
                    id="dropzone-file1"
                    type="file"
                    className="hidden"
                    multiple
                    // value={imageList}
                    onChange={(e) => {
                      showImageList1(e.target.files);
                    }}
                  />
                </label>
              </div>
            </div>

            <div className="mb-4.5">
              <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 gap-4">
                {imageList1.map((items, index) => (
                  <div
                    key={items}
                    className="w-full group relative cursor-pointer h-25 flex justify-center items-center rounded border-2 border-gray-300 border-dashed "
                  >
                    <img
                      src={items}
                      alt="images"
                      width={0}
                      height={0}
                      sizes="100vw"
                      style={{ width: "100%", height: "100%" }}
                      className=" object-contain"
                    />

                    <button
                      type="button"
                      className="transition transform w-full h-full
                            translate-y-8 ease-in-out invisible  
                            absolute group-hover:visible 
                            text-white group-hover:translate-y-0 bg-black flex justify-center items-center opacity-75"
                      onClick={() => {
                        if (
                          typeof items === "string" &&
                          !items.startsWith("blob")
                        )
                          removedImages.push(data.session1[index]);
                        imageList1.splice(index, 1);
                        data.session1.splice(index, 1);
                        setData({
                          ...data,
                          session1: data.session1,
                        });
                        setImageList1(imageList1);
                      }}
                    >
                      <svg
                        className="fill-current"
                        width="30"
                        height="30"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                          fill=""
                        />
                        <path
                          d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                          fill=""
                        />
                        <path
                          d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                          fill=""
                        />
                        <path
                          d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                          fill=""
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full flex justify-center items-center">
              <div className="p-6.5">
                <button
                  type="submit"
                  onClick={() => {
                    updateSlides();
                  }}
                  className="flex w-full justify-center rounded bg-primary py-3 px-20 font-medium text-gray"
                >
                  {loading ? (
                    <div className="flex h-auto items-center justify-center ">
                      <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                    </div>
                  ) : (
                    "Update Slides"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
