import { useState } from "react";
import { useEffect } from "react";
import { AYURVEDHA } from "../../../api/ayurvedha";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { COMPANYCONTACT } from "../../../api/companycontact";


export default function CompanyContactView() {
    let { id } = useParams();
    const [data, setData] = useState({
        address: "",
        telephone: "",
        email: "",
    });

    const getAyurvedha = async (id) => {
        try {
            const res = await COMPANYCONTACT.GET_BY_ID(id);
            if (res.status !== 200) {
                throw new Error("Failed to fetch contact information");
            }
            console.log("s", res)
            setData({
                ...data,
                _id: res.data._id,
                address: res.data.address,
                telephone: res.data.telephone,
                email: res.data.email,

            });
            data._id = res.data._id;
            data.address = res.data.address;
            data.telephone = res.data.telephone;
            data.email = res.data.email;
        } catch (error) {
            console.log("Error loading topics: ", error);
        }

    };
    useEffect(() => {
        getAyurvedha(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return (
        <div>
            <Breadcrumbs pageName="View Company Contact" />
            <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                    <h3 className="font-medium text-black dark:text-white">
                        {data.title}
                    </h3>
                </div>

                <div className="grid grid-cols-1 gap-9 sm:grid-cols-2">
                    <div className="flex flex-col gap-9 ">
                        <div className="p-6.5">
                            <div className="mb-4.5">
                                <label className="mb-2.5 block text-black dark:text-white">
                                    Address <span className="text-meta-1">*</span>
                                </label>
                                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                                    {data.address}
                                </p>
                            </div>
                            <div className="mb-4.5">
                                <label className="mb-2.5 block text-black dark:text-white">
                                    Phone Number <span className="text-meta-1">*</span>
                                </label>
                                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                                    {data.telephone}
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="flex flex-col gap-9 h-auto">
                        <div className="p-6.5 h-auto">

                            <div className="mb-4.5">
                                <label className="mb-2.5 block text-black dark:text-white">
                                    Email <span className="text-meta-1">*</span>
                                </label>
                                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                                    {data.email}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}