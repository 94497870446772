import { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { UseAuth } from "../../../utils/UseAuth";
import { ROUTE } from "../../../api/route";
import { CONSTANT } from "../../../constant/Constant";
import { TokenContext } from "../../../utils/TokenContext";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";

export default function CreateRoute() {
  const navigate = useNavigate();
  const { token } = useContext(TokenContext);
  const [loading, setLoading] = useState(false);
  const [imageView, setImageView] = useState("");
  const { checkAccessTokenValidity } = UseAuth();
  const [reRunData, setReRunData] = useState(null);
  const [refreshAdd, setRefreshAdd] = useState(false);

  const [data, setData] = useState({
    url: "",
    route: "",
    menu: "",
    image: "",
    imgView: "",
    isPurged: false,
  });

  // console.log("data", data);

  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .required("Required")
      .test(
        "starts-with-slash",
        "URL must start with a single slash",
        (value) => {
          return value && value.startsWith("/");
        }
      )
      .test(
        "single-slash",
        "URL must not contain more than one additional slash",
        (value) => {
          if (!value) return true; // Allow empty if required is not triggered
          const slashCount = (value.match(/\//g) || []).length;
          return slashCount === 1; // Allow only one additional slash
        }
      ),
  });

  const clearData = () => {
    setData({
      ...data,
      url: "",
      route: "",
      menu: "",
      image: "",
      imgView: "",
    });
  };

  const handleImageChange = (file) => {
    const fileLoaded = URL.createObjectURL(file);
    setData({
      ...data,
      image: file,
      imgView: fileLoaded,
    });
    setImageView(fileLoaded);
  };

  // const addData = async (formData) => {
  //   // setLoading(true);

  //   try {
  //     const res = await ROUTE.ADD(formData, token);
  //     setLoading(true);
  //     setReRunData(null);
  //     clearData();
  //     console.log("formData", res);
  //     if (res.status === 200) {
  //       // toast.success("Created New Route");
  //       // navigate("/route");
  //       return res;
  //     } else {
  //       toast.error("Failed to create a new route");
  //       return null;
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("error", error);
  //     if (error.response && error.response.status === 401) {
  //       await checkAccessTokenValidity();
  //       setReRunData(formData);
  //       setRefreshAdd(!refreshAdd);
  //     } else {
  //       toast.error("Failed to create a route");
  //     }
  //   }
  //   return null;
  // };

  // const handleSubmit = async (values) => {
  //   if (window.confirm("Are you sure you want to add this data?")) {
  //     console.log("data.image", data.image);
  //     if (data.image === "") {
  //       toast.info("Please add an image.");
  //       return;
  //     }
  //     setLoading(true);
  //     const formData = new FormData();
  //     formData.append("url", values.url);
  //     formData.append("menu", data.image);
  //     // Await the response from addData
  //     const rtnVal = await addData(formData);

  //     if (rtnVal !== null) {
  //       // Success case, show success message and navigate
  //       // toast.success("Created New Route");
  //       // clearData(); // Clear form data after success
  //       navigate("/route"); // Navigate only on success
  //       setRefreshAdd(!refreshAdd); // Trigger refresh if needed
  //     } else {
  //       // Failure case (handled in addData already)
  //       console.log("Failed to add the route.");
  //     }
  //     toast.success("Created New Route");
  //     clearData(); // Clear form data after success
  //     navigate("/route");
  //   }
  //   setLoading(false);
  // };

  // Function to handle adding data
  const addData = async (formData) => {
    try {
      setLoading(true);
      const res = await ROUTE.ADD(formData, token);
      console.log("Response data:", res.data);

      if (res.status === 200) {
        return res.data;
      } else {
        toast.error("Failed to create a new route");
        return null;
      }
    } catch (error) {
      setLoading(false);
      console.error("Error caught in catch block:", error);

      // Check for specific error codes and handle accordingly
      if (error.response && error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(formData);
        setRefreshAdd(!refreshAdd);
      } else {
        toast.error("Failed to create a route");
      }
    }
  };

  // Function to handle form submission
  const handleSubmit = async (values) => {
    if (window.confirm("Are you sure you want to add this data?")) {
      try {
        // console.log("data.image", data.image);
        if (!data.image) {
          toast.info("Please add an image.");
          return;
        }

        // setLoading(true);
        const formData = new FormData();
        formData.append("url", values.url); // Append URL
        formData.append("menu", data.image); // Append image

        // // Log the FormData key-value pairs
        // for (let pair of formData.entries()) {
        //   console.log(`${pair[0]}: ${pair[1]}`);
        // }

        // Await the response from addData
        const rtnVal = addData(formData);

        if (rtnVal !== null) {
          toast.success("Created New Route");
          clearData(); // Clear form data after success
          navigate("/route"); // Navigate only on success
          setRefreshAdd(!refreshAdd); // Trigger refresh if needed
        } else {
          console.error("Failed to add the route.");
        }
      } catch (error) {
        console.error("An error occurred while adding the route:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false); // Ensure loading is stopped
      }
    }
  };

  // Re-run data if necessary (from previous submission)
  useEffect(() => {
    if (reRunData !== null) {
      addData(reRunData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAdd]);

  useEffect(() => {
    console.log("Component mounted");

    return () => {
      console.log("Component unmounted");
    };
  }, []);

  return (
    <div>
      <Breadcrumbs pageName="Add Routes" />
      <div className="rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Insert New Data
          </h3>
        </div>
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values }) => (
            <Form>
              <div className="grid grid-cols-1 gap-9 sm:grid-cols-2 ">
                <div className="flex flex-col gap-9 ">
                  <div className="p-6.5">
                    <div className="mb-4.5">
                      <label className="mb-2.5 block text-black dark:text-white">
                        Route Path <span className="text-meta-1">*</span>
                      </label>
                      <Field
                        placeholder="/seafood-menu"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        name="url"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        component="a"
                        className="text-danger"
                        name="url"
                      />
                    </div>
                  </div>
                  <div className="p-6.5">
                    <div className="mb-4.5 flex gap-3">
                      <p className="italic">URL is going to be:</p>
                      <p>
                        {CONSTANT.BASE_URL}menu{values.url}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-9 p-6.5">
                  <div className=" w-full">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Upload Image
                      <span className="text-meta-1">*</span>
                    </label>
                    <label
                      htmlFor="image"
                      className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-50 hover:bg-gray dark:border-gray dark:hover:border-gray dark:hover:bg-gray"
                    >
                      {data.imgView !== "" ? (
                        <div className="w-full group relative cursor-pointer h-full flex justify-center items-center rounded ">
                          <img
                            src={data.imgView}
                            alt="images"
                            unoptimized={true}
                            width={0}
                            height={0}
                            sizes="100vw"
                            style={{ width: "100%", height: "100%" }}
                            className=" object-contain"
                            // className="h-full object-contain"
                          />

                          <button
                            type="button"
                            className="transition transform w-full h-full
                            translate-y-8 ease-in-out invisible  
                            absolute group-hover:visible 
                            text-white group-hover:translate-y-0 bg-black flex justify-center items-center opacity-75 "
                            onClick={() => {
                              setData({
                                ...data,
                                image: "",
                                imgView: "",
                              });
                            }}
                          >
                            <svg
                              className="fill-current"
                              width="30"
                              height="30"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                                fill=""
                              />
                              <path
                                d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                                fill=""
                              />
                              <path
                                d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                                fill=""
                              />
                              <path
                                d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                                fill=""
                              />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <>
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload image
                              </span>
                              {/* or drag and drop */}
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              PNG, JPG or JPEG (MAX. 800x400px)
                            </p>
                          </div>
                          <input
                            id="image"
                            type="file"
                            className="hidden "
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) => {
                              handleImageChange(e.target.files[0]);
                            }}
                          />
                        </>
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-9 sm:grid-cols-2 ">
                <div className="p-6.5">
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                  >
                    {loading ? (
                      <div className="flex h-auto items-center justify-center ">
                        <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                      </div>
                    ) : (
                      "Add Route"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
