import { useContext, useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { UseAuth } from "../../../utils/UseAuth";
import { AuthContext } from "../../../utils/AuthContext";
import { BLOGS } from "../../../api/blogs"; // Update with your blogs API

export default function BlogUpdate() {
  const { checkAccessTokenValidity } = UseAuth();
  const { token } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    title: "",
    description: "",
    auther: "",
    blogImage: "",
    imgView: "",
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    auther: Yup.string().required("Required"),
  });

  useEffect(() => {
    const getBlog = async (id) => {
      try {
        const res = await BLOGS.GET_BY_ID(id);
        if (res.status !== 200) {
          throw new Error("Failed to fetch blog information");
        }
        const blogData = res.data;
        setData({
          _id: blogData._id,
          title: blogData.title,
          description: blogData.description,
          auther: blogData.auther,
          blogImage: blogData.blogImage,
          imgView: `/blog/${blogData.blogImage}`,
        });
      } catch (error) {
        console.error("Error loading blog: ", error);
      }
    };
    getBlog(id);
  }, [id]);

  const handleImageChange = (file) => {
    const fileLoaded = URL.createObjectURL(file);
    setData((prevData) => ({
      ...prevData,
      blogImage: file,
      imgView: fileLoaded,
    }));
  };

  const updateData = async (formData, id) => {
    try {
      const res = await BLOGS.UPDATE(formData, id, token);
      setLoading(false);
      if (res.status === 200) {
        toast.success("Blog updated successfully");
        navigate("/blog");
      } else {
        toast.error("Failed to update blog");
        throw new Error("Failed to update blog");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating blog: ", error);
      if (error.response && error.response.status === 401) {
        await checkAccessTokenValidity();
        setLoading(true);
      } else {
        toast.error("Failed to update blog");
      }
    }
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("auther", values.auther);
    if (data.blogImage) {
      formData.append("blogImage", data.blogImage);
    }
    updateData(formData, data._id);
  };

  return (
    <div>
      <Breadcrumbs pageName="Update Blog" />
      <div className="rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Update Blog Details
          </h3>
        </div>
        <Formik
          initialValues={data}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="grid grid-cols-1 gap-9 sm:grid-cols-2">
            <div className="flex flex-col gap-9">
              <div className="p-6.5">
                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Title <span className="text-meta-1">*</span>
                  </label>
                  <Field
                    placeholder="Enter the blog title"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="title"
                  />
                  <ErrorMessage
                    component="a"
                    className="text-danger"
                    name="title"
                  />
                </div>
                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Description <span className="text-meta-1">*</span>
                  </label>
                  <Field
                    as="textarea"
                    placeholder="Enter the blog content"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="description"
                    rows={5}
                  />
                  <ErrorMessage
                    component="a"
                    className="text-danger"
                    name="description"
                  />
                </div>
                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Auther <span className="text-meta-1">*</span>
                  </label>
                  <Field
                    placeholder="Enter the author's name"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="auther"
                  />
                  <ErrorMessage
                    component="a"
                    className="text-danger"
                    name="auther"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-9 h-auto">
              <div className="p-6.5 h-auto">
                <div className="mb-8 flex flex-col gap-6 xl:flex-row">
                  <div className="w-full">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Blog Image
                      <span className="text-meta-1">*</span>
                    </label>
                    <div className="flex items-center justify-center w-full">
                      <label
                        htmlFor="blog-image-file"
                        className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-50 hover:bg-gray dark:border-gray dark:hover:border-gray dark:hover:bg-gray"
                      >
                        {data.imgView !== "" ? (
                          <div className="w-full group relative cursor-pointer h-full flex justify-center items-center rounded">
                            <img
                              src={data.imgView}
                              alt="Blog Image"
                              className="h-full object-contain"
                            />
                            <button
                              type="button"
                              className="transition transform w-full h-full translate-y-8 ease-in-out invisible absolute group-hover:visible text-white group-hover:translate-y-0 bg-black flex justify-center items-center opacity-75"
                              onClick={() => {
                                setData((prevData) => ({
                                  ...prevData,
                                  blogImage: "",
                                  imgView: "",
                                }));
                              }}
                            >
                              <svg
                                className="fill-current"
                                width="30"
                                height="30"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.316 6.1594C14.8504 5.9344 15.216 5.42815 15.216 4.8094V3.96565C15.216 3.15002 14.541 2.47502 13.7535 2.47502ZM7.35352 1.9969C7.35352 1.64815 7.55477 1.4469 7.90352 1.4469H10.0691C10.4179 1.4469 10.6191 1.64815 10.6191 1.9969V2.47502H7.35352V1.9969Z"
                                  fill="white"
                                />
                              </svg>
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                aria-hidden="true"
                                className="w-10 h-10 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M7 16l-4-4m0 0l4-4m-4 4h18M13 8l4 4m0 0l-4 4m4-4H3"
                                ></path>
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray">
                                PNG, JPG (MAX. 800x400px)
                              </p>
                            </div>
                          </>
                        )}
                      </label>
                      <input
                        id="blog-image-file"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => {
                          handleImageChange(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-6.5 p-6.5">
              <button
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                type="submit"
                disabled={loading}
              >
                Update Blog
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
