import React from "react";
import { HOME } from "../../../assets";

export default function Banner() {
  return (
    <div className="w-full h-full relative overflow-hidden">
      {/* Parallax Background Image */}
      <div
        className="h-[830px] bg-cover bg-center lg:bg-fixed md:bg-scroll"
        style={{ backgroundImage: `url(${HOME.BANNER_IMG})` }}
      ></div>

      {/* Overlay for Opacity Control */}
      <div className="absolute inset-0 bg-white opacity-50"></div>

      {/* Content Section */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="absolute w-[65%] py-[100px] h-auto flex flex-col lg:flex-row max-w-screen-2xl justify-center top-2">
          <div className="flex justify-center flex-col lg:flex-row text-center lg:text-start">
            <p className="lg:w-[45%] w-full 2xl:text-5xl lg:text-4xl md:text-4xl text-3xl font-felixTitling lg:mr-8">
              Luxury, Business, and Leisure Perfected
            </p>
            <p className="lg:w-[55%] w-full lg:text-xl text-lg font-poppins lg:font-light font-semibold leading-7 lg:py-0 md:py-10 py-5">
              Experience exceptional comfort with our diverse room options,
              including Luxury Suites and Premium Rooms. Enjoy versatile event
              spaces, from grand Banquet Halls to high-tech Conference Rooms.
              Relax at our Ayurvedic Spa and savor fine dining, all designed to
              warmly welcome you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
