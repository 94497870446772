// import img from "../../assets/rustic-3c.jpg";
import img from "../../assets/contact.jpg";
import React, { useEffect, useState } from "react";
import { ICONS } from "../../assets";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
import { CONTACT } from "../../api/contact";
import { COMPANYCONTACT } from "../../api/companycontact";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //constant variables
  const MyTextArea = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
      <>
        <textarea
          className="w-full h-30 font-poppins py-3 px-3 border-[1.5px] border-stroke bg-white  font-medium outline-none transition focus:border-black active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-black"
          {...field}
          {...props}
        />
      </>
    );
  };
  const [loading, setLoading] = useState(false);
  const [ayurvedha, setAyurvedha] = useState([]);


  // const MyTextArea = ({ label, ...props }) => {
  //     const [field] = useField(props);
  //     return (
  //       <>
  //         <textarea
  //           className="w-full h-36 font-poppins py-3 px-3 border-[1.5px] border-stroke bg-transparent  font-medium outline-none transition focus:border-black active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-black"
  //           {...field}
  //           {...props}
  //         />
  //       </>
  //     );
  //   };

  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
    isPurged: false,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    message: Yup.string().required("Required").min(10, "Too Short!"),
    email: Yup.string().required("Required").email("Invalid email address"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const res = await CONTACT.ADD(values);
      setLoading(false);
      if (res.status === 200) {
        toast.success("Your message has been sent successfully!");
        setData(values);
        resetForm();
      } else {
        toast.error("Failed to send message. Please try again later.");
        throw new Error("Failed to send message");
      }
    } catch (error) {
      setLoading(false);
      console.error("Failed to send message:", error);
      toast.error("Failed to send message. Please try again later.");
    }
  };

  const getEventList = async () => {
    try {
      console.log("Fetching event list...");
      const res = await COMPANYCONTACT.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch ayurvedha information");
      }
      console.log("Event list fetched successfully:", res.data);
      setAyurvedha(res.data);
      console.log("AyurvedhaAyurvedha", ayurvedha)
    } catch (error) {
      console.error("Error loading event list:", error);
      if (error.response?.status === 401) {
        console.error("Unauthorized access. Token might have expired.");
      } else {
        console.error("Unexpected error:", error.message);
      }
    }
  };


  useEffect(() => {
    getEventList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="h-auto w-full">
      <div className="flex flex-col">
        <div className=" w-full h-full lg:h-150 md:h-100">
          <img
            src={img}
            alt="images"
            style={{ width: "100%", height: "100%" }}
            className=" object-cover"
          />
        </div>

        <div className="w-full h-full items-center justify-center py-10">
          <div className="flex flex-col gap-3 pb-10">
            <p className="text-3xl font-felixTitling text-[#000] text-center">
              CONTACT US
            </p>
            <p className="text-lg font-poppins text-[#000] text-center">
              “We listen, we care, we deliver.”
            </p>
          </div>
          <div className="px-[20px] md:px-[100px] grid md:grid-cols-2 gap-10 text-[#000]">
            <div className="flex flex-col gap-5 text-[#000] pt-10">
              {ayurvedha?.map((contact, index) => (
                <React.Fragment key={index}>
                  <div className="flex flex-row gap-3">
                    <div className="w-[25px] h-[25px]">
                      <img src={ICONS.Address} className="w-full h-full object-over" />
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="font-bold text-sm font-poppins">Address:</p>
                      <p className="text-sm font-poppins">{contact.address}</p>
                    </div>
                  </div>
                  <div className="flex flex-row gap-3 text-[#000]">
                    <div className="w-[25px] h-[25px]">
                      <img src={ICONS.Phone} className="w-full h-full object-over" />
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="font-bold text-sm font-poppins">Phone:</p>
                      <p className="text-sm font-poppins">{contact.telephone}</p>
                    </div>
                  </div>
                  <div className="flex flex-row gap-3 text-[#000]">
                    <div className="w-[25px] h-[25px]">
                      <img src={ICONS.Email} className="w-full h-full object-cover" />
                    </div>
                    <div className="flex flex-col gap-2 text-[#000]">
                      <p className="font-bold text-sm font-poppins">Email:</p>
                      <p className="text-sm font-poppins">{contact.email}</p>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>

            <div className="bg-gray p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4 text-center">
                Send Message
              </h3>
              <Formik
                initialValues={data}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="space-y-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Full Name
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className="mt-1 block w-full px-4 py-2 border border-gray outline-none rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter Your Name"
                    />
                    <ErrorMessage
                      component="a"
                      className="text-danger"
                      name="name"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="mt-1 block w-full px-4 py-2 border border-gray outline-none rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Enter Your email"
                    />
                    <ErrorMessage
                      component="a"
                      className="text-danger"
                      name="email"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="message"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Message
                    </label>
                    <MyTextArea
                      name="message"
                      rows="4"
                      placeholder="Please explain your enquiry"
                      onInput={(e) => {
                        // Allow only alphabets, @, dots, and commas, remove others
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z@.,? ]/g,
                          ""
                        );
                      }}
                    />
                    <ErrorMessage
                      component="a"
                      className="text-danger"
                      name="message"
                    />
                  </div>
                  <div className=" w-full h-auto py-5 ">
                    <button
                      className="w-full py-2 px-4 bg-blue text-white font-bold rounded-md hover:bg-teal-600"
                      type="submit"
                    >
                      {loading ? (
                        <div className="flex h-auto items-center justify-center ">
                          <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                        </div>
                      ) : (
                        "SEND"
                      )}{" "}
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
