// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Default widths */
.slide-width-20 {
  width: auto  !important;
}
.slide-width-201 {
  width: auto !important;
}
.slide-width-202 {
  width: auto !important;
}
.slide-width-50 {
  width: auto !important;
}

/* Mobile styles */
/* @media (max-width: 374px) {
  .slide-width-20,
  .slide-width-201,
  .slide-width-202,
  .slide-width-50 {
    width: 100% ;
  }
}

/* Tablet styles */
/* @media (min-width: 375px) and (max-width: 767px) {
  .slide-width-20,
  .slide-width-201,
  .slide-width-202,
  .slide-width-50 {
    width: 50%  ;
  } */ 
/* } */
`, "",{"version":3,"sources":["webpack://./src/pages/unAuth/home/Package.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,uBAAuB;AACzB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,sBAAsB;AACxB;;AAEA,kBAAkB;AAClB;;;;;;;;;kBASkB;AAClB;;;;;;KAMK;AACL,MAAM","sourcesContent":["/* Default widths */\r\n.slide-width-20 {\r\n  width: auto  !important;\r\n}\r\n.slide-width-201 {\r\n  width: auto !important;\r\n}\r\n.slide-width-202 {\r\n  width: auto !important;\r\n}\r\n.slide-width-50 {\r\n  width: auto !important;\r\n}\r\n\r\n/* Mobile styles */\r\n/* @media (max-width: 374px) {\r\n  .slide-width-20,\r\n  .slide-width-201,\r\n  .slide-width-202,\r\n  .slide-width-50 {\r\n    width: 100% ;\r\n  }\r\n}\r\n\r\n/* Tablet styles */\r\n/* @media (min-width: 375px) and (max-width: 767px) {\r\n  .slide-width-20,\r\n  .slide-width-201,\r\n  .slide-width-202,\r\n  .slide-width-50 {\r\n    width: 50%  ;\r\n  } */ \r\n/* } */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
